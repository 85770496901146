@import "antd/dist/reset.css";

.logo-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  font-size: large;
  font-weight: bold;
}
